import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";

const Nav = ({ onLogout }) => {
  const [adminName, setAdminName] = useState(
    localStorage.getItem("AQATO_SuperAdminName")
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const toggleRef = useRef(null);
  const menuRef = useRef(null);
  const [logoutClicked, setLogoutClicked] = useState(false);
  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);

  useEffect(() => {
    setAdminName(localStorage.getItem("AQATO_SuperAdminName"));
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        toggleRef.current &&
        !toggleRef.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle logout click
  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  // Confirm logout action
  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    navigate("/");
    toast.success("Logged out successfully!");
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  // Cancel logout action
  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  // Toggle dropdown on user icon click
  const handleDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <>
      <Row className="mb-0">
        <Col
          className="d-flex justify-content-end align-items-center"
          style={{ marginRight: "25px", color: "#055837" }}
        >
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              id="user-dropdown"
              className="border-0 text-dark d-flex align-items-center"
              style={{ color: "#055837", outline: "none", boxShadow: "none" }}
            >
              <BsFillPersonFill className="me-1" style={{ color: "#055837" }} />
              <span
                style={{
                  color: "#055837",
                  textDecoration: "none",
                  transition: "text-decoration 0.2s ease",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.textDecoration = "underline";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.textDecoration = "none";
                }}
              >
                Welcome <b>{adminName}</b>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              ref={menuRef}
              style={{
                border: "2px solid #ffcc01",
                borderRadius: "5px",
                backgroundColor: "transparent",
                padding: "0",
                alignItems: "center",
                position: "absolute",
                zIndex: 1000,
              }}
            >
              <Dropdown.Item
                onClick={handleLogout}
                className="custom-dropdown-item"
              >
                <i className="bi bi-power"></i> Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      {logoutClicked && (
        <ConfirmationModal
          show={showConfirmationModalForLogout}
          onHide={handleConfirmationLogoutNo}
          onConfirm={handleConfirmationLogoutYes}
          title="Confirm Logout"
          message="Are you sure you want to Log Out?"
        />
      )}
    </>
  );
};

export default Nav;
