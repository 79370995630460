import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import Reporting from "./Reporting";
import Accounting from "./Accounting";
import DataAnalytics from "./Data Analytics";

const AdminFunctions = () => {

  const [ViewUpdateTabActive, setViewUpdateTabActive] = useState(false);
  const [SubmittedDocsTabActive, setSubmittedDocsTabActive] = useState(false);
  const [NotesTabActive, setNotesTabActive] = useState(false);







  useEffect(() => {
    if (localStorage.getItem(`admin_funtions_reporting_tab_active`) === "true") {
      setViewUpdateTabActive(true);
      setSubmittedDocsTabActive(false);
      setNotesTabActive(false);
    } else if (
      localStorage.getItem(`admin_functions_accounting_tab_active`) === "true"
    ) {
      setSubmittedDocsTabActive(true);
      setViewUpdateTabActive(false);
      setNotesTabActive(false);
    } else if (
      localStorage.getItem(`admin_functions_data_anlytics_tab_active`) ===
      "true"
    ) {
      setNotesTabActive(true);
      setSubmittedDocsTabActive(false);
      setViewUpdateTabActive(false); // Set view update tab active to false
    } else {
      setViewUpdateTabActive(true);
      setSubmittedDocsTabActive(false);
      setNotesTabActive(false);
    }
  }, []);









  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";


  useEffect(() => {
    const tabs = document.querySelectorAll(".nav_top_bar");

    tabs.forEach((tab) => {
      const tabId = tab.getAttribute("id");

      if (tabId === "tabs_view_edit") {
        tab.setAttribute(
          "aria-selected",
          ViewUpdateTabActive ? "true" : "false"
        );
      } else if (tabId === "tabs_documents") {
        tab.setAttribute(
          "aria-selected",
          SubmittedDocsTabActive ? "true" : "false"
        );
      } else if (tabId === "notes_tab") {
        tab.setAttribute("aria-selected", NotesTabActive ? "true" : "false");
      }
    });
  }, [ViewUpdateTabActive, SubmittedDocsTabActive, NotesTabActive]);

  return (
    <>
      <div className="main_desktop mb-5">
        <hr className="mb-2 thick-hr" />

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Admin Functions
              </h3>
            </div>
            <div className="main-body-area">
              <Card className="shadow">
                <div className="card card-primary card-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs">
                      <li
                        className="nav-item"
                        style={{ marginTop: "0px", cursor: "pointer" }}
                      >
                        <a
                          className={`nav-link nav_inactive_tab_btn ${
                            ViewUpdateTabActive ? "nav_active_tab_btn" : ""
                          }`}
                          id="tabs_view_edit"
                          // href="#"
                          role="tab"
                          aria-controls="tabs_view_edit"
                          aria-selected={ViewUpdateTabActive ? "true" : "false"}
                          onClick={() => {
                            setViewUpdateTabActive(true);
                            setSubmittedDocsTabActive(false);
                            setNotesTabActive(false);

                            localStorage.setItem(
                              `admin_funtions_reporting_tab_active`,
                              "true"
                            );
                            localStorage.setItem(
                              `admin_functions_accounting_tab_active`,
                              "false"
                            );
                            localStorage.setItem(
                              `admin_functions_data_anlytics_tab_active`,
                              "false"
                            );
                          }}
                        >
                          <h6 className="h6">Reporting</h6>
                        </a>
                      </li>



                      {accountType === "super_admin" && (
                      <li
                        className="nav-item"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className={`nav-link nav_inactive_tab_btn ${
                            SubmittedDocsTabActive ? "nav_active_tab_btn" : ""
                          }`}
                          id="tabs_documents"
                          // href="#"
                          role="tab"
                          aria-controls="tabs_documents"
                          aria-selected={
                            SubmittedDocsTabActive ? "true" : "false"
                          }
                          onClick={() => {
                            setSubmittedDocsTabActive(true);
                            setViewUpdateTabActive(false);
                            setNotesTabActive(false);

                            localStorage.setItem(
                              `admin_funtions_reporting_tab_active`,
                              "false"
                            );
                            localStorage.setItem(
                              `admin_functions_accounting_tab_active`,
                              "true"
                            );
                            localStorage.setItem(
                              `admin_functions_data_anlytics_tab_active`,
                              "false"
                            );
                          }}
                        >
                          <h6 className="h6">Accounting</h6>
                        </a>
                      </li>
                      )}

                      

                      <li
                        className="nav-item"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className={`nav-link nav_inactive_tab_btn ${
                            NotesTabActive ? "nav_active_tab_btn" : ""
                          }`}
                          id="notes_tab"
                          onClick={() => {
                            setNotesTabActive(true);
                            setViewUpdateTabActive(false);
                            setSubmittedDocsTabActive(false);

                            localStorage.setItem(
                              `admin_funtions_reporting_tab_active`,
                              "false"
                            );
                            localStorage.setItem(
                              `admin_functions_accounting_tab_active`,
                              "false"
                            );
                            localStorage.setItem(
                              `admin_functions_data_anlytics_tab_active`,
                              "true"
                            );
                          }}
                          role="tab"
                          aria-controls="notes_tab"
                          aria-selected={NotesTabActive ? "true" : "false"}
                        >
                          <h6 className="h6">Data Analysis</h6>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-3">
                  {ViewUpdateTabActive && <Reporting />}
                  {SubmittedDocsTabActive && <Accounting />}
                  {NotesTabActive && <DataAnalytics />}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminFunctions;
