import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heading from "../Heading/Heading";
import { LoginButton } from "../Common Modules/Common Modules"; // Corrected import path
import "./Forgot Password.css"; // Corrected import path
import { Card, Form, Modal, Button, Spinner } from "react-bootstrap"; // Combined import for better readability
import "bootstrap-icons/font/bootstrap-icons.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgotPasswordAPI } from "../../api";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CaptchaComponent from "../Captcha Component/Captcha Component"; // Corrected import path
import Loader from "../Widgets/Loader/Loader";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const handleCaptchaVerification = (isVerified) => {
    setIsCaptchaVerified(isVerified);
  };

  const [showConfirmationSuccessModal, setShowConfirmationSuccessModal] =
    useState(false);
  const [accountCreatedSuccessfully, setAccountCreatedSuccessfully] =
    useState(false);

  const handleConfirmationAccountCreatedNo = () => {
    setShowConfirmationSuccessModal(false);
  };

  const handleConfirmationAccountCreatedYes = () => {
    setShowConfirmationSuccessModal(false);
    navigate("/");
    setAccountCreatedSuccessfully(false);
    toast.success(
      "Link sent to your email for updating password, kindly check your nominated email."
    );
  };

  const handleSuccessful = () => {
    setAccountCreatedSuccessfully(true);
    setShowConfirmationSuccessModal(true);
  };

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();

  // const navigation_path = `/admin/reset_password?email=${email}`;
  const navigation_path = `/admin/reset_password`;

  const handleSubmit = async () => {
    if (!email || !validateEmail(email)) {
      setEmailError(true);
      toast.error("Please enter a valid email address.");
      document.getElementById("email").focus();
      return;
    }

    // if (!isCaptchaVerified) {
    //   toast.error("Verification code does not match.");
    //   return;
    // }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("email", email);
      formData.append("navigation_path", navigation_path);

      const response = await forgotPasswordAPI(formData);

      setLoading(false);

      const responseData = response.data?.response;

      if (responseData.response === true) {
        handleSuccessful();
      } else {
        setLoading(false);

        if (responseData.error_msg) {
          toast.error(responseData.error_msg);
        } else {
          toast.error(
            "An error occurred during password reset. Please try again."
          );
        }
      }
    } catch (error) {
      setLoading(false);

      console.error("Update password failed:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        setLoading(false);

        toast.error(
          "An error occurred during password reset. Please try again."
        );
      }
    }
  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setEmailError(!validateEmail(emailInput));
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <Heading text="Forgot Password" />

      {loading && <Loader />}

      <div className="forgot-container-like-login-containnnn">
        <Card className="forgot-card-like-login-containnnn">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Forgot Password</b>
          </div>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                controlId="formBasicEmail"
                className="forgot-card-input"
              >
                <div className="input-group" style={{ height: "40px" }}>
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#e9ecef", height: "100%" }}
                    id="emailInputGroup"
                  >
                    <i
                      className="bi bi-envelope-fill"
                      style={{
                        fontSize: "1.5rem",
                        color: "black",
                        fontWeight: "bold",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></i>
                  </span>
                  <Form.Control
                    type="email"
                    placeholder="Please Enter your Registered Email"
                    value={email}
                    onChange={handleEmailChange}
                    style={{ height: "100%" }}
                    id="email"
                    aria-describedby="emailInputGroup"
                  />
                </div>

                {emailError && (
                  <div className="error-message">
                    Invalid Email Address. Please enter a valid email. *{" "}
                  </div>
                )}
              </Form.Group>

              {/* <CaptchaComponent
                onCaptchaVerification={handleCaptchaVerification}
              /> */}
            </Form>
          </Card.Body>

          {/* {loading && (
            <div className="d-flex justify-content-center mt-3">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )} */}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <LoginButton
              onClick={handleSubmit}
              buttonText={"Retrieve Password"} // Corrected typo
              icon={false}
            />
          </div>

          <div
            style={{
              textAlign: "center",
            }}
          >
            <div className="centered-content">
              <p
                style={{
                  fontFamily: "Arial",
                  fontSize: "16px",
                  fontWeight: "normal",
                  cursor: "pointer",
                }}
              >
                <a
                  className="text-green"
                  style={{
                    textDecoration: "none",
                    color: "#055837",
                  }}
                  onClick={() => navigate("/")}
                  onMouseOver={(e) =>
                    (e.target.style.textDecoration = "underline")
                  }
                  onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: "5px" }}
                  />
                  Back to Login
                </a>
              </p>
            </div>
          </div>

          {accountCreatedSuccessfully && (
            <Modal show={showConfirmationSuccessModal} centered>
              <Modal.Header>
                <Modal.Title
                  className="text-center w-100"
                  style={{
                    fontSize: "18px",
                    fontFamily: "Arial, sans-serif",
                    color: "#055837",
                  }}
                >
                  Update password Link Sent Successfully !
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                className="text-left"
                style={{ fontSize: "16px", fontFamily: "Arial, sans-serif" }}
              >
                <ul style={{ listStyle: "none", paddingLeft: "0" }}>
                  <li style={{ marginBottom: "10px" }}>
                    <span style={{ color: "black" }}>•</span> An email has been
                    sent to your nominated email address (
                    <strong>{email}</strong>) with your account activation link.
                    Please check your email inbox for instructions on how to
                    reset your password. If you don't see the email within a few
                    minutes, please also check your spam or junk folder. If you
                    need further assistance, please contact our support team at{" "}
                    <a href="mailto:skills@aqato.com.au">skills@aqato.com.au</a>{" "}
                    for assistance.
                  </li>
                </ul>
              </Modal.Body>

              <Modal.Footer className="d-flex justify-content-center">
                <Button
                  variant="light"
                  onClick={handleConfirmationAccountCreatedYes}
                  className="w-50"
                  style={{
                    maxWidth: "130px",
                    backgroundColor: "#055837",
                    color: "#ffcc01",
                    transition: "transform 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.transform = "scale(1.02)")
                  }
                  onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Card>
      </div>
    </>
  );
};

export default ForgotPassword;
