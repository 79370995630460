import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row } from "react-bootstrap";
import {
  getOccupationManager_API,
  DeleteOccupationAPI,
  ChangeStatusOccupationAPI,
} from "../../api";

import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import { FaCaretDown } from "react-icons/fa";
import AddOccupationMoadal from "./AddOccupationModal/AddOccupationModal";
import EditOccupationMoadal from "./EditOccupationModal/EditOccupationModal";
import Loader from "../Widgets/Loader/Loader";
import VerificationModal from "./VerificationModal/VerificationModal";

const OccupationManager = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };
  const [SelectedItem, setSelectedItem] = useState(null);

  const [showAddOccupationModal, setAddOccupationShow] = useState(false);
  const handlAddOccupationClose = () => setAddOccupationShow(false);
  const handlAddOccupationShow = () => setAddOccupationShow(true);

  const [showEditOccupationModal, setEditOccupationShow] = useState(false);
  const handlEditOccupationClose = () => setEditOccupationShow(false);
  const handleEditOccupationShow = (item) => {
    setSelectedItem(item);
    setEditOccupationShow(true);
    setChangeStatusModalShow(false);
    setDeleteAccountShow(false);
    setAddOccupationShow(false);
  };

  const [showDeleteAccountModal, setDeleteAccountShow] = useState(false);
  const handleDeleteAccountClose = () => setDeleteAccountShow(false);
  const handleDeleteAccountShow = (item) => {
    setSelectedItem(item);
    setDeleteAccountShow(true);
    setChangeStatusModalShow(false);
    setEditOccupationShow(false);
    setAddOccupationShow(false);
  };

  const [showChangeStatusModal, setChangeStatusModalShow] = useState(false);
  const handleChangeStatusModalClose = () => setChangeStatusModalShow(false);
  const handleChangeStatusModalShow = (item) => {
    setSelectedItem(item);
    setChangeStatusModalShow(true);
    setDeleteAccountShow(false);
    setEditOccupationShow(false);
    setAddOccupationShow(false);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const visiblePages = 5;
  const totalPages = Math.ceil(originalData.length / dataPerPageButton);
  // const startPage = 1;
  const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  const getOccupationsFunction = async () => {
    try {
      setLoading(true);

      const response = await getOccupationManager_API();

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const RES_Data = response?.data?.response?.data;
        setOriginalData(RES_Data);
        setCurrentPageData(RES_Data.slice(0, parseInt(dataPerPageButton)));
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getOccupationsFunction();
  }, []);

  const handleFirst = () => {
    setCurrentPage(1);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLast = () => {
    setCurrentPage(Math.ceil(originalData.length / dataPerPageButton));
  };


  useEffect(() => {
    // Calculate index of the last item on the current page
    const indexOfLastItem = currentPage * dataPerPageButton;
    // Calculate index of the first item on the current page
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    // Slice the original data array to get current page's data
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    // Update current page data state
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);



  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);



  // useEffect(() => {
  //   if (searchQuery === "") {
  //     setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
  //   } else {
  //     const filteredData = originalData.filter((item) =>
  //       Object.values(item).some(
  //         (value) =>
  //           value &&
  //           value.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //       )
  //     );
  //     setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));
  //   }
  // }, [searchQuery, dataPerPageButton, originalData]);






  useEffect(() => {
    const startIndex = (currentPage - 1) * dataPerPageButton;
    const endIndex = currentPage * dataPerPageButton;

    // Filter the original data based on the search query
    let filteredData = originalData;
    if (searchQuery !== "") {
      filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    // Slice the filtered data based on the current page
    const currentPageData = filteredData.slice(startIndex, endIndex);

    setCurrentPageData(currentPageData);
  }, [searchQuery, dataPerPageButton, originalData, currentPage]);



  const handleDeleteYes = async (SelectedItem) => {
    try {
      setLoading(true);

      // Create a new FormData object
      const formData = new FormData();
      formData.append("id", SelectedItem?.id);

      const response = await DeleteOccupationAPI(formData);

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        toast.success(
          response.data.response.success_msg ||
          "Occupation deleted successfully."
        );
        getOccupationsFunction();
        handleDeleteAccountClose();
        setSelectedItem(null);
      } else {
        toast.error(response.data.error_msg || "Error deleting occupation.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred.");
    }
  };

  const handleChangeStatus = async (SelectedItem) => {
    try {
      setLoading(true);

      const response = await ChangeStatusOccupationAPI(SelectedItem?.id);

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        toast.success(
          response.data.response.success_msg ||
          "Occupation Status Changed successfully."
        );
        getOccupationsFunction();
        handleChangeStatusModalClose();
        setSelectedItem(null);
      } else {
        toast.error(
          response.data.error_msg || "Error changing status of occupation."
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred.");
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>

      {loading && <Loader />}

      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />


        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Occupation Manager
              </h3>
            </div>
            <div className="main-body-area mb-5">
              <Card className="shadow">
                <div className="mt-3">
                  <div className="action_buttons_top bg-white action_btn_desk">
                    <Row className="my-2 mx-2 align-items-center">
                      <Col lg="1" className="my-auto">
                        <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
                          <select
                            value={dataPerPageButton}
                            onChange={handleDataPerPageChange}
                            style={{
                              width: '100%',
                              padding: '6px',
                              border: '1px solid #ccc',
                              borderRadius: '5px',
                              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                              appearance: 'none', // Remove default arrow
                              WebkitAppearance: 'none', // Safari compatibility
                              MozAppearance: 'none', // Firefox compatibility
                              backgroundColor: 'white',
                              cursor: 'pointer'
                            }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          <FaCaretDown
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translateY(-50%)',
                              pointerEvents: 'none', // Prevent interaction with the icon
                              fontSize: '14px',
                              color: '#333'
                            }}
                          />
                        </div>

                      </Col>
                      <Col lg="9">
                        <Row className="justify-content-center align-items-center search-btn">
                          <Col lg="12">
                            <Form.Control
                              type="search"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearch}
                              className="searchInput"
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col lg="2" className="my-auto text-end d-flex plus-btn">
                        <button
                          data-bs-toggle="modal"
                          className="btn btn-sm btn_green_yellow ms-1"
                          onClick={handlAddOccupationShow}
                          style={{ padding: "8px" }}
                        >
                          <i className="bi bi-plus"></i>
                          Add Occupation
                        </button>
                      </Col>
                    </Row>
                  </div>

                  <div
                    id="table_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12">
                          <div class="table-responsive table-wrapper">
                            <table
                              class="table table-striped table-hover dataTable no-footer"
                              style={{ cursor: "default" }}
                            >
                              <thead>
                                <tr>
                                  <th className="col-1 th_font_size">Sr.No.</th>
                                  <th className="col-5 th_font_size">
                                    Occupation Name
                                  </th>
                                  <th className="col-3 th_font_size">
                                    Number Code
                                  </th>
                                  <th className="col-1  th_font_size">
                                    Status
                                  </th>
                                  <th className="col-1 th_font_size">Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {currentPageData.map((item, index) => (
                                  <tr
                                    key={index}
                                    className={index % 2 === 0 ? "even" : "odd"}
                                  >
                                    <td className="td_font_size">
                                      {index + 1}.
                                    </td>
                                    <td className="td_font_size">
                                      {item?.name}
                                    </td>
                                    <td>{item?.number}</td>
                                    <td className="td_font_size">
                                      <button
                                        className={`btn btn-sm ms-1 ${item.status === "inactive"
                                            ? "btn-danger"
                                            : "btn_green_yellow"
                                          }`}
                                        style={{
                                          width: "85px",
                                          height: "35px",
                                        }}
                                        onClick={() =>
                                          handleChangeStatusModalShow(item)
                                        }
                                      >
                                        <strong>
                                          {item?.status?.toUpperCase()}
                                        </strong>
                                      </button>
                                    </td>

                                    <td className="btn-flex">
                                      <button
                                        data-bs-toggle="modal"
                                        data-bs-target="#edit_form1"
                                        className="btn btn-sm btn_green_yellow ms-1"
                                        onClick={() =>
                                          handleEditOccupationShow(item)
                                        }
                                      >
                                        <i className="bi bi-pencil-square"></i>
                                      </button>


                                      &nbsp;
                                      <button
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_req2"
                                        className="btn btn-sm btn-danger ms-1"
                                        onClick={() =>
                                          handleDeleteAccountShow(item)
                                        }
                                        disabled={true}
                                      >
                                        <i className="bi bi-trash-fill"></i>
                                      </button>




                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="thick-hr" />

                    <div className="pagination-container p-3">
                      <div className="dataTables_info">
                        Showing{" "}
                        {currentPage === 1
                          ? 1
                          : (currentPage - 1) * dataPerPageButton + 1}{" "}
                        to{" "}
                        {Math.min(currentPage * dataPerPageButton, originalData.length)}{" "}
                        of {originalData.length} entries
                      </div>

                      {/* <span 
    style={{
      background: 'linear-gradient(to bottom right, #fff9c4, #fff3e0)', 
      color: '#055837', 
      padding: '8px 12px',
      borderRadius: '5px',
      display: 'inline-block',
      cursor: "default"
    }}
  >
    Active Page : {currentPage}
  </span> */}

                      <div className="pagination-buttons">
                        <button
                          className={`paginate_button ${currentPage === 1 ? "disabled" : ""}`}
                          onClick={handleFirst}
                          disabled={currentPage === 1}
                        >
                          First
                        </button>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <button
                            className={`paginate_button ${startPage === 1 ? "disabled" : ""}`}
                            onClick={handlePrev}
                            disabled={startPage === 1}
                          >
                            Previous
                          </button>

                          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', flex: 1 }}>
                            {Array.from({ length: visiblePages }, (_, index) => {
                              const pageNumber = startPage + index;
                              if (pageNumber > totalPages) return null;

                              const isActive = currentPage === pageNumber;

                              return (
                                <button
                                  key={pageNumber}
                                  className={`paginate_button ${isActive ? "active" : ""}`}
                                  style={{
                                    display: 'inline-block',
                                    margin: '0 4px',
                                    backgroundColor: isActive ? "black" : "transparent",
                                    color: isActive ? "white" : "black",
                                  }}
                                  onClick={() => handlePageClick(pageNumber)}
                                >
                                  {pageNumber}
                                </button>
                              );
                            })}
                          </div>

                          <button
                            className={`paginate_button ${startPage + visiblePages - 1 >= totalPages ? "disabled" : ""}`}
                            onClick={handleNext}
                            disabled={startPage + visiblePages - 1 >= totalPages}
                          >
                            Next
                          </button>
                        </div>

                        <button
                          className={`paginate_button ${currentPage === totalPages ? "disabled" : ""}`}
                          onClick={handleLast}
                          disabled={currentPage === totalPages}
                        >
                          Last
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {showAddOccupationModal && (
        <AddOccupationMoadal
          showModal={showAddOccupationModal}
          handleClose={handlAddOccupationClose}
          getOccupationsFunction={getOccupationsFunction}
        />
      )}

      {showEditOccupationModal && SelectedItem && (
        <EditOccupationMoadal
          showModal={showEditOccupationModal}
          handleClose={() => handlEditOccupationClose && setSelectedItem(null)}
          SelectedItem={SelectedItem}
          getOccupationsFunction={getOccupationsFunction}
        />
      )}

      {showDeleteAccountModal && SelectedItem && (
        <VerificationModal
          show={showDeleteAccountModal}
          onClose={() => handleDeleteAccountClose && setSelectedItem(null)}
          onYes={() => handleDeleteYes(SelectedItem)}
          Title="Confirm Delete!"
          Message={
            <>
              Are you sure you want to delete{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                &nbsp; {SelectedItem?.name}
              </span>
              &nbsp; Occupation ?
            </>
          }
        />
      )}

      {showChangeStatusModal && SelectedItem && (
        <VerificationModal
          show={showChangeStatusModal}
          onClose={() => handleChangeStatusModalClose && setSelectedItem(null)}
          onYes={() => handleChangeStatus(SelectedItem)}
          Title="Confirm Change Status !"
          Message={
            <>
              Are you sure you want to change{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: SelectedItem?.status === "active" ? " #055837" : "red",
                }}
              >
                &nbsp; {capitalizeFirstLetter(SelectedItem?.status)}
              </span>
              &nbsp; to{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: SelectedItem?.status === "active" ? "red" : " #055837",
                }}
              >
                &nbsp;{" "}
                {capitalizeFirstLetter(
                  SelectedItem?.status === "active" ? "inactive" : "active"
                )}
              </span>
              &nbsp; of <strong>{SelectedItem?.name}</strong> Occupation ?
            </>
          }
        />
      )}
    </>
  );
};

export default OccupationManager;
