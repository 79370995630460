import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getAllOccupationsAPI,
  AddEditSAQ_OfOfflineFilesAPI,
  getSingleSAQ_OfOfflineFilesAPI,
} from "../../../api";
import Loader from "../../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditSQA = ({
  showModal,
  handleClose,
  selectedItem,
  getDataSAQFunction,
}) => {
  const useFor = "saq_file";

  const [loading, setLoading] = useState(false);
  const [occupation, setOccupation] = useState("");

  const [file, setFile] = useState(null);

  const [fileCh, setFileCh] = useState(false);

  const [error, setError] = useState("");
  const [occupations, setOccupations] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    occupation: "",
    file: "",
  });

  useEffect(() => {
    if (selectedItem) {
      setOccupation(selectedItem?.occupation_id || "");
      setFileCh(false);
    } else{setOccupation(null)}
  }, [selectedItem]);

  useEffect(() => {
    const fetchOccupations = async () => {
      try {
        setLoading(true);

        const response = await getAllOccupationsAPI();
        setLoading(false);

        if (response.data?.response && response.data?.response?.data) {
          setOccupations(response.data.response.data);
        } else {
          console.error(
            "Error fetching occupations:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching occupations:", error);
      }
    };

    fetchOccupations();
  }, []);

  const fetchSingleData = async () => {
    try {
      setLoading(true);

      const formdata = new FormData();
      formdata.append("id", selectedItem?.id);

      const response = await getSingleSAQ_OfOfflineFilesAPI(formdata);
      setLoading(false);

      if (response.data?.response && response.data?.response?.data) {
        setOccupation(response.data?.response?.data?.occupation_id);
        setFileCh(true);
      } else {
        console.error(
          "Error fetching occupations:",
          response.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations:", error);
    }
  };
  useEffect(() => {
    if (selectedItem && selectedItem?.id) {
      fetchSingleData();
    }
  }, []);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/zip",
      "application/x-zip-compressed", // for handling ZIP files in different browsers
    ];

    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setFileCh(true);

      setError("");
      setValidationErrors((prev) => ({ ...prev, file: "" }));
    } else {
      setFile(null);
      setError(
        "You have to choose a file with one of the allowed formats (PDF, DOC, DOCX, XLS, XLSX, ZIP)."
      );
      setValidationErrors((prev) => ({
        ...prev,
        file: "Only PDF, DOC, DOCX, XLS, XLSX, or ZIP files are allowed.",
      }));
    }
  };

  const handleOccupationChange = (e) => {
    setOccupation(e.target.value);
    setValidationErrors((prev) => ({ ...prev, occupation: "" }));
  };

  const validateForm = () => {
    let isValid = true;
    if (!occupation) {
      setValidationErrors((prev) => ({
        ...prev,
        occupation: "Occupation is required.",
      }));
      isValid = false;
    }
    if (!file) {
      setValidationErrors((prev) => ({ ...prev, file: "File is required." }));
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const formdata = new FormData();
      formdata.append("use_for", useFor);
      formdata.append("name", "");
      formdata.append("occupation_id", occupation);
      formdata.append("file", file);
      formdata.append("id", selectedItem?.id ? selectedItem.id : "");

      try {
        setLoading(true);
        const response = await AddEditSAQ_OfOfflineFilesAPI(formdata);
        setLoading(false);
        if (
          response &&
          response.data?.response &&
          response.data.response.response === true
        ) {
          getDataSAQFunction();
          setFileCh(false);

          toast.success(
            response.data.response.success_msg || "Saved Successfully."
          );
          handleClose();
        } else {
          toast.error(response.data?.response?.error_msg || "Error in saving.");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error saving data:", error);
        toast.error("Error in saving.");
      }
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={showModal}
        onHide={handleClose}
        animation={false}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title
            style={{ color: "#055837", cursor: "default" }}
            className="modal-title"
          >
            {selectedItem && selectedItem?.path_text && selectedItem?.file_name
              ? "Edit SAQ"
              : "Add SAQ"}
          </Modal.Title>
        </Modal.Header>

        <div className="modal-content">
          <div className="modal-body">
            <form onSubmit={handleSubmit} className="edit_data">
              <div className="row">
                <div className="col-6">
                  <label>
                    Occupation <span className="text-danger">*</span>
                  </label>
                  <Form.Group controlId="occupationSelect">
                    <Form.Select
                      value={occupation}
                      onChange={handleOccupationChange}
                      style={{ cursor: "pointer" }}
                    >
                      <option value="" disabled>
                        Select Occupation
                      </option>
                      {occupations.map((occupation) => (
                        <option key={occupation.id} value={occupation.id}>
                          {occupation.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {validationErrors.occupation && (
                    <div style={{ color: "red" }}>
                      {validationErrors.occupation}
                    </div>
                  )}
                </div>

                <div className="col-6">
                  <label>
                    File <span className="text-danger">*</span>
                  </label>

                  <input
                    name="file"
                    type="file"
                    className="form-control"
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .zip"
                    onChange={handleFileChange}
                    style={{ height: "35px" }}
                  />

                  {error && <div style={{ color: "red" }}>{error}</div>}
                  {validationErrors.file && (
                    <div style={{ color: "red" }}>{validationErrors.file}</div>
                  )}

                  <div>
                    <br />
                    {!fileCh &&
                      selectedItem &&
                      selectedItem?.path_text &&
                      selectedItem?.file_name && (
                        <div>
                          <span
                            style={{
                              fontWeight: "bold",
                              cursor: "default",
                              marginRight: "8px",
                              color: "#055837",
                            }}
                          >
                            Uploaded File:
                          </span>

                          <a
                            href={selectedItem.path_text}
                            download={selectedItem.file_name}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              textDecoration: "none",
                            }}
                            onMouseOver={(e) =>
                              (e.target.style.textDecoration = "underline")
                            }
                            onMouseOut={(e) =>
                              (e.target.style.textDecoration = "none")
                            }
                          >
                            {selectedItem.file_name}
                          </a>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn_yellow_green"
                    onClick={handleClose}
                    style={{ marginRight: "10px" }}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn_green_yellow"
                    style={{ backgroundColor: "#28a745", color: "#fff" }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditSQA;
