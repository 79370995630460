import React, { useState, useEffect, useRef } from "react";
import AQATO_header_image from "./AQATO_header_image.jpg";
import "./Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Nav from "./Nav";

const Header = ({ onLogout }) => {
  const [adminName, setAdminName] = useState(
    localStorage.getItem("AQATO_SuperAdminName")
  );
  const navigate = useNavigate();
  const location = useLocation();
  const toggleRef = useRef(null);


  useEffect(() => {
    setAdminName(localStorage.getItem("AQATO_SuperAdminName"));
  }, [localStorage.getItem("AQATO_SuperAdminName")]);

  useEffect(() => {
    setAdminName(localStorage.getItem("AQATO_SuperAdminName"));
  }, []);

  useEffect(() => {
    if (toggleRef.current) {
      const toggleWidth = toggleRef.current.offsetWidth;
      document.documentElement.style.setProperty(
        "--dropdown-width",
        `${toggleWidth}px`
      );
    }
  }, [adminName]);


  const showDropdown = ![
    "/",
    "/admin/forgot_password",
    "/admin/reset_password",
  ].includes(location.pathname);

  return (
    <>
      <div className="header-container">
        <div className={`logo-container ${!showDropdown ? "centered" : ""}`}>
          <img
            src={AQATO_header_image}
            alt="AQATO_image"
            className="header-image"
          />
        </div>

        <div>
          {showDropdown && adminName !== "" && <Nav onLogout={onLogout} />}
        </div>
      </div>
    </>
  );
};

export default Header;
