import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Widgets/Loader/Loader";
import { cancelPracticalBookingAPI } from "../../../api";
import VerificationModal from "../VerificationModal/VerificationModal";

const CancelInterview = ({
  showModal,
  handleClose,
  fetchPracticalBookingData,
  selectedItem,
}) => {
  const [loading, setLoading] = useState(false);

  const [showDeleteAccountModal, setDeleteAccountShow] = useState(false);
  const handleDeleteAccountClose = () => setDeleteAccountShow(false);
  const handleDeleteAccountShow = () => {
    setDeleteAccountShow(true);
  };

  const [selectedReason, setSelectedReason] = useState("");
  const [showOtherTextarea, setShowOtherTextarea] = useState(false);
  const [otherReason, setOtherReason] = useState("");

  const handleReasonChange = (event) => {
    const value = event.target.value;
    setSelectedReason(value);
    setShowOtherTextarea(value === "Other");
  };

  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };

  const handleSubmitCheck = () => {
    if (!selectedReason) {
      toast.error("Please select a reason.");
      return;
    }

    if (showOtherTextarea && !otherReason) {
      toast.error("Please provide the other reason.");
      return;
    }

    handleDeleteAccountShow();
  };

  const handleFakeSubmit = async (event) => {
    event.preventDefault();
  }


  const handleSubmit = async () => {
    // event.preventDefault();

console.log("jfniulwhjeuifhnwuiLHFNUWILSEhnfulesnlfijdaewhnilufjahwednfivuajlehndijgfvhndexiuagfvhbuie")

    // Check if the required fields are filled
    if (!selectedReason) {
      toast.error("Please select a reason.");
      return; // Stop form submission
    }

    if (showOtherTextarea && !otherReason) {
      toast.error("Please provide the other reason.");
      return; // Stop form submission
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("comment", otherReason);
      formData.append("options", selectedReason);
      formData.append("id", selectedItem?.id);

      const response = await cancelPracticalBookingAPI(formData);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setSelectedReason("");
        setShowOtherTextarea(false);
        setOtherReason("");
        handleClose();
        handleDeleteAccountClose();
        fetchPracticalBookingData();
        toast.success(
          response?.data?.response?.success_msg ||
            "Practical Booking Cancelled Successfully."
        );
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in cancelling."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal show={showModal} onHide={handleClose} animation={false} centered>
        <form
        onSubmit={handleFakeSubmit}
        >
          <Modal.Header>
            <Modal.Title
              style={{ cursor: "default" }}
              className="modal-title text-center text-green"
            >
              <h5>Cancel Practical Booking</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ cursor: "default" }}>
            <h5 className="modal-title text-center text-green">
            {selectedItem?.application_no} - {selectedItem?.applicant_name} - {selectedItem?.occupation}
            </h5>

            <div className="form-group mt-1">
              <label htmlFor="options_16">
                <b>
                  Reason <span className="text-danger">*</span>
                </b>
              </label>
              <select
                id="options_16"
                name="options"
                className="form-select md-4"
                value={selectedReason}
                onChange={handleReasonChange}
                required
                style={{ cursor: "pointer" }}
              >
                <option selected disabled value="">
                  Choose Reason
                </option>
                <option value="Requested by the Applicant/Agent">
                  Requested by the Applicant/Agent
                </option>
                <option value="Requested by the Assessor">
                  Requested by the Assessor
                </option>
                <option value="No Show">No Show</option>
                <option value="Other">Other</option>
              </select>

              {showOtherTextarea && (
                <>
                  <div className="mt-1">
                    <label htmlFor="otherReasonTextarea" className="mt-1">
                      <b>
                        Other Reason <span className="text-danger">*</span>
                      </b>
                    </label>
                    <textarea
                      id="otherReasonTextarea"
                      className="form-control"
                      placeholder="Please specify"
                      value={otherReason}
                      onChange={handleOtherReasonChange}
                      required={showOtherTextarea}
                    ></textarea>
                  </div>
                </>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn_modal"
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              onClick={handleClose}
            >
              Back
            </button>
            <button
              className="btn-danger ms-1"
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              // onClick={handleSubmit}
              onClick={handleSubmitCheck}
              variant="primary"
              type="submit"
            >
              Cancel Request
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {showDeleteAccountModal && selectedItem && showModal && (
        <VerificationModal
          show={showDeleteAccountModal}
          onClose={handleDeleteAccountClose}
          onYes={handleSubmit}
          Title="Confirm Delete!"
          Message={
            <>
              Are you sure you want to{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                &nbsp; Cancel Interview Booking
              </span>
              &nbsp; ?
            </>
          }
        />
      )}
    </>
  );
};

export default CancelInterview;
