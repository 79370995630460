import React, { useEffect, useState } from "react";
import "./Interview Bookings.css";
import NonAqato from "./NonAqato/NonAqato";
import Aqato from "./Aqato/Aqato";
import AqatoReassesment from "./AqatoReassesment/AqatoReassesment";
import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";

const InterviewBookings = () => {
  const [AqatoTabActive, setAqatoTabActive] = useState(false);
  const [NonAqatoTabActive, setNonAqatoTabActive] = useState(false);
  const [AqatoReassesmentTabActive, setAqatoReassesmentTabActive] =
    useState(false);

  useEffect(() => {
    if (
      localStorage.getItem(`interview_bookings_aqato_tab_active`) === "true"
    ) {
      setAqatoTabActive(true);
      setNonAqatoTabActive(false);
      setAqatoReassesmentTabActive(false);
    } else if (
      localStorage.getItem(`interview_bookings_non_aqato_tab_active`) === "true"
    ) {
      setNonAqatoTabActive(true);
      setAqatoTabActive(false);
      setAqatoReassesmentTabActive(false);
    } else if (
      localStorage.getItem(
        `interview_bookings_aqato_reassessment_tab_active`
      ) === "true"
    ) {
      setAqatoReassesmentTabActive(true);
      setNonAqatoTabActive(false);
      setAqatoTabActive(false); // Set view update tab active to false
    } else {
      setAqatoTabActive(true);
      setNonAqatoTabActive(false);
      setAqatoReassesmentTabActive(false);
    }
  }, []);

  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h4 className="heading-admin-title text-color-green">
                Interview Bookings
              </h4>
            </div>

            <div className="card card-primary card-tabs">
              <div className="card-header p-0 border-bottom-0">
                <ul className="nav nav-tabs">
                  <li
                    className="nav-item"
                    style={{ marginTop: "0px", cursor: "pointer" }}
                  >
                    <a
                      className={`nav-link nav_inactive_tab_btn ${
                        AqatoTabActive ? "nav_active_tab_btn" : ""
                      }`}
                      id="tabs_view_edit"
                      // href="#"
                      role="tab"
                      aria-controls="tabs_view_edit"
                      aria-selected={AqatoTabActive ? "true" : "false"}
                      onClick={() => {
                        setAqatoTabActive(true);
                        setNonAqatoTabActive(false);
                        setAqatoReassesmentTabActive(false);

                        localStorage.setItem(
                          `interview_bookings_aqato_tab_active`,
                          "true"
                        );
                        localStorage.setItem(
                          `interview_bookings_non_aqato_tab_active`,
                          "false"
                        );
                        localStorage.setItem(
                          `interview_bookings_aqato_reassessment_tab_active`,
                          "false"
                        );
                      }}
                    >
                      <h6 className="h6">AQATO</h6>
                    </a>
                  </li>
                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={`nav-link nav_inactive_tab_btn ${
                        NonAqatoTabActive ? "nav_active_tab_btn" : ""
                      }`}
                      id="tabs_documents"
                      // href="#"
                      role="tab"
                      aria-controls="tabs_documents"
                      aria-selected={NonAqatoTabActive ? "true" : "false"}
                      onClick={() => {
                        setNonAqatoTabActive(true);
                        setAqatoTabActive(false);
                        setAqatoReassesmentTabActive(false);

                        localStorage.setItem(
                          `interview_bookings_aqato_tab_active`,
                          "false"
                        );
                        localStorage.setItem(
                          `interview_bookings_non_aqato_tab_active`,
                          "true"
                        );
                        localStorage.setItem(
                          `interview_bookings_aqato_reassessment_tab_active`,
                          "false"
                        );
                      }}
                    >
                      <h6 className="h6">NON - AQATO</h6>
                    </a>
                  </li>

                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={`nav-link nav_inactive_tab_btn ${
                        AqatoReassesmentTabActive ? "nav_active_tab_btn" : ""
                      }`}
                      id="interview_bookings_aqato_reassessment_tab_active"
                      onClick={() => {
                        setAqatoReassesmentTabActive(true);
                        setAqatoTabActive(false);
                        setNonAqatoTabActive(false);

                        localStorage.setItem(
                          `interview_bookings_aqato_tab_active`,
                          "false"
                        );
                        localStorage.setItem(
                          `interview_bookings_non_aqato_tab_active`,
                          "false"
                        );
                        localStorage.setItem(
                          `interview_bookings_aqato_reassessment_tab_active`,
                          "true"
                        );
                      }}
                      role="tab"
                      aria-controls="interview_bookings_aqato_reassessment_tab_active"
                      aria-selected={
                        AqatoReassesmentTabActive ? "true" : "false"
                      }
                    >
                      <h6 className="h6">AQATO - REASSEMENT</h6>{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {AqatoTabActive && <Aqato />}
            {NonAqatoTabActive && <NonAqato />}
            {AqatoReassesmentTabActive && <AqatoReassesment />}
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewBookings;
