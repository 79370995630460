import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Widgets/Loader/Loader";
import {
  getAllApplicantsLocationsAPI,
  getPracticalBookingDataPopupForSelectListAPI,
  postNewPracticalBookingAPI,
} from "../../../api";
import VerificationModal from "../VerificationModal/VerificationModal";

const NewBookingModal = ({
  showModal,
  handleClose,
  fetchPracticalBookingData,
}) => {
  const [loading, setLoading] = useState(false);

  const [showDeleteAccountModal, setDeleteAccountShow] = useState(false);
  const handleDeleteAccountClose = () => setDeleteAccountShow(false);
  const handleDeleteAccountShow = () => {
    setDeleteAccountShow(true);
  };

  const [selectedVenue, setSelectedVenue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedApplicant, setSelectedApplicant] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const [ApplicantsList, setApplicantsList] = useState([]);
  const [LocationsByAPI, setLocationsByAPI] = useState([]);
  const [TimeList, setTimeList] = useState([]);

const today = new Date().toLocaleDateString('en-CA', { 
  timeZone: 'Australia/Brisbane'
});

  const getPracticalBookingDataFunction = async () => {
    try {
      setLoading(true);

      const response = await getPracticalBookingDataPopupForSelectListAPI();

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        // setLocationsByAPI(response?.data?.response?.data?.venues);
        setApplicantsList(response?.data?.response?.data?.lodged_users);
        setTimeList(response?.data?.response?.data?.time_options);

        setSelectedApplicantStage(response?.data?.response?.data?.stage);
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  // useEffect(() => {
  //   getPracticalBookingDataFunction();
  // }, []);

  useEffect(() => {
    if (showModal) {
      getPracticalBookingDataFunction();
    }
  }, [showModal]);

  const [selectedApplicantStage, setSelectedApplicantStage] = useState("");

  const getLocationsDataFunction = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("id", selectedApplicant);
      formData.append("stage", selectedApplicantStage);

      const response = await getAllApplicantsLocationsAPI(formData);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setSelectedVenue(response?.data?.response?.data?.id);
        
        setLocationsByAPI(response?.data?.response?.data?.venues);


        //  setSelectedZone(response?.data?.response?.data?.time_zone);
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    if (showModal && selectedApplicant && selectedApplicantStage) {
      getLocationsDataFunction();
    }
  }, [showModal, selectedApplicant, selectedApplicantStage]);

  const handleClear = () => {
    setSelectedVenue("");
    setSelectedDate("");
    setSelectedApplicant("");
    setSelectedTime("");
  };

  const handleVenueChange = (event) => {
    setSelectedVenue(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleApplicantChange = (event) => {
    setSelectedApplicant(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleSubmitCheck = () => {
    if (
      !selectedApplicant ||
      !selectedVenue ||
      !selectedDate ||
      !selectedTime
    ) {
      toast.error("Please fill in all fields.");
      return;
    }

    handleDeleteAccountShow();
  };

  const handleFakeSubmit = async (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    // event.preventDefault();

    if (
      !selectedApplicant ||
      !selectedVenue ||
      !selectedDate ||
      !selectedTime
    ) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("applicant_name_id", selectedApplicant);
      formData.append("venue", selectedVenue);
      formData.append("date", selectedDate);
      formData.append("time", selectedTime);

      const response = await postNewPracticalBookingAPI(formData);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        // Clear form fields
        setSelectedVenue("");
        setSelectedDate("");
        setSelectedApplicant("");
        setSelectedTime("");

        toast.success(
          response?.data?.response?.success_msg ||
            "Practical Booking done successfully."
        );

        handleClose();
        handleDeleteAccountClose();
        handleClear();
        fetchPracticalBookingData();
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Unknown Error.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  // Function to find the time for the selected venue
  const getSelectedVenueTime = () => {
    if (!selectedVenue) return null; // No venue selected

    for (const countryObj of LocationsByAPI) {
      for (const [country, locations] of Object.entries(countryObj)) {
        const location = locations.find(
          (loc) => loc.venue_id.toString() === selectedVenue
        );
        if (location) {
          return location.time_select; // Return the corresponding time
        }
      }
    }
    return null; // No time found
  };

  const selectedTimeZoneLabel = getSelectedVenueTime();

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={showModal}
        onHide={() => {
          handleClose();
          handleClear();
        }}
        animation={false}
        centered
      >
        <form onSubmit={handleFakeSubmit}>
          <Modal.Header>
            <Modal.Title className="modal-title text-center text-green">
              <h5>New Practical Booking (AQATO)</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group">
              <b>
                <label htmlFor="select_location_new">
                  Applicant <span className="text-danger">*</span>
                </label>
              </b>
              <select
                name="applicant_name_id"
                id="select_location_new"
                className="form-select md-4"
                value={selectedApplicant}
                onChange={handleApplicantChange}
                required
                style={{ cursor: "pointer" }}
              >
                <option style={{ cursor: "pointer" }} disabled value="">
                  Select Applicant
                </option>
                {ApplicantsList.map((applicant) => (
                  <option
                    key={applicant.stage_4_id}
                    value={applicant.stage_4_id}
                    style={{ cursor: "pointer" }}
                  >
                    {applicant.fullname}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label
                htmlFor="venue-select"
                className="form-label label-font-size"
              >
                <b>
                  Venue <span className="text-danger">*</span>{" "}
                </b>
              </label>
              <select
                name="venue"
                id="venue-select"
                className="form-select"
                value={selectedVenue}
                onChange={handleVenueChange}
                required
                style={{ cursor: "pointer" }}
              >
                <option
                  style={{ cursor: "pointer" }}
                  className="dropdown-options"
                  value=""
                  disabled
                >
                  Select Location
                </option>
                {LocationsByAPI.map((countryObj) =>
                  Object.entries(countryObj).map(([country, locations]) => (
                    <optgroup
                      key={country}
                      label={country}
                      style={{ fontWeight: "bold" }}
                    >
                      {locations.map((location) => (
                        <option
                          key={location.venue_id}
                          value={location.venue_id}
                          style={{ cursor: "pointer" }}
                        >
                          {location.venue_name}
                        </option>
                      ))}
                    </optgroup>
                  ))
                )}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="date" className="form-label label-font-size">
                <b>
                  Date <span className="text-danger">*</span>
                </b>
              </label>
              <input
                name="date"
                type="date"
                className="form-control"
                value={selectedDate}
                onChange={handleDateChange}
                min={today}
                required
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="form-group">
              <b>
                <label htmlFor="select_location_new">
                  Time{" "}
                  {selectedVenue
                    ? `(${selectedTimeZoneLabel})`
                    : "(QLD)"}{" "}
                  <span className="text-danger">*</span>
                </label>
              </b>
              <select
                name="applicant_name_id"
                id="select_location_new"
                className="form-select md-4"
                value={selectedTime}
                onChange={handleTimeChange}
                required
                style={{ cursor: "pointer" }}
              >
                <option style={{ cursor: "pointer" }} disabled value="">
                  Select Time
                </option>
                {TimeList.map((tm) => (
                  <option
                    style={{ cursor: "pointer" }}
                    key={tm.time_start}
                    value={tm.time_start}
                  >
                    {tm.time_start}
                  </option>
                ))}
              </select>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn_yellow_green"
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              onClick={() => {
                handleClose();
                handleClear();
              }}
            >
              Close
            </button>
            <button
              className="btn_modal"
              variant="primary"
              // onClick={handleSubmit}
              onClick={handleSubmitCheck}
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              type="submit"
            >
              Book Practical
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {showDeleteAccountModal && showModal && (
        <VerificationModal
          show={showDeleteAccountModal}
          onClose={() => {
            handleDeleteAccountClose();
            handleClear();
          }}
          onYes={handleSubmit}
          Title="Confirm Booking!"
          Message={
            <>
              Are you sure you want to{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                &nbsp; Add New Practical Interview Booking
              </span>
              &nbsp; ?
            </>
          }
        />
      )}
    </>
  );
};

export default NewBookingModal;
