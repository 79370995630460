import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./NameKeywords.css";
import { addNameKeywordTemplate } from "../../../api";
import Loader from "../../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Add = ({ showModal, handleClose, getNameKeywordFunction }) => {
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    name_keyword: "",
    name: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmitFake = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate fields
    if (!formValues.name_keyword.trim()) {
      toast.error("Please fill the 'Keyword' field.");
      return;
    }
    if (!formValues.name.trim()) {
      toast.error("Please fill the 'Name' field.");
      return;
    }

    const formData = new FormData();
    formData.append("name_keyword", formValues.name_keyword);
    formData.append("name", formValues.name);
    setLoading(true);

    try {
      const response = await addNameKeywordTemplate(formData);
      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        // toast.success(
        //   response?.data?.response?.success_msg ||
        //     "Name Keyword Added Successfully."
        // );

        toast.success("Name Keyword Added Successfully.");
        getNameKeywordFunction();
        handleClose();
      } else {
        console.error(
          "Error updating location data:",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in saving.");
      }
    } catch (error) {
      console.log("Error ", error);
      toast.error("An error occurred while saving.");
    } finally {
      handleClose();
      getNameKeywordFunction();
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={showModal}
        onHide={handleClose}
        className="model_mannual"
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Add Name Keyword</h4>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmitFake} className="add_data">
              <div className="row">
                <div className="col-6">
                  <label>
                    Keyword
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name_keyword"
                    value={formValues.name_keyword}
                    onChange={handleInputChange}
                    className="form-control md-2"
                    required
                  />
                </div>
                <div className="col-6">
                  <label>
                    Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                    className="form-control md-2"
                    required
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn_yellow_green"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    Close
                  </button>

                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn_green_yellow ml-2"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Add;
