import React, { useEffect, useRef } from "react";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.css";
import "./TomCustomCss.css";

const TomSelectComponent = ({ 
  options, 
  onChange, 
  placeholder = "Select an option", 
  value,
  plugins = []
}) => {
  const selectRef = useRef(null);

  useEffect(() => {
    const tomSelectInstance = new TomSelect(selectRef.current, {
      options: options.map(option => ({ value: option.value, text: option.label })),
      onChange: onChange,
      placeholder: placeholder,
      plugins: plugins // Add plugins here
    });

    // Set the selected value when the component is mounted
    if (value) {
      tomSelectInstance.setValue(value);
    }

    // Cleanup on component unmount
    return () => {
      tomSelectInstance.destroy();
    };
  }, [options, onChange, placeholder, value]);

  return (
    <select ref={selectRef}>
      <option value="">{placeholder}</option>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default TomSelectComponent;
