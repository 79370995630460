import React, {useRef, useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

import {
  postUploadSingleFileForMailOfVerificationQualificationApplicationManager,
  postSendAllDataForMailOfVerificationQualificationApplicationManager,
  postRemoveFileForMailOfVerificationQualificationApplicationManager,
  getAllDataForQualificationVerificationMailAppManager,
  getAllUploadedFilesForQualificationVerificationMailAppManager,
} from "../../../../../api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../Widgets/Loader/Loader";
import VerificationModal from "../VerificationModal/VerificationModal";

const EditRTODetailsModal = ({
  show,
  handleClose,
  getIndividualEmploymentFunction,
  pointerID,
  call,
  SelectedItem,
}) => {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const [ApplicantName, setApplicantName] = useState("");
  const [ApplicantPRN, setApplicantPRN] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [inputs, setInputs] = useState([]);
  const [emailRTOError, setEmailRTOError] = useState("");
  const [qualificationLinks, setQualificationLinks] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [QualificationDocs, setQualificationDocs] = useState({});
  const [RTOEmail, setRTOEmail] = useState("");

  const [ccEmailFilled, setCcEmailFilled] = useState(true);


  const [formValues, setFormValues] = useState({
    files: [],
    error: "",
    fileError: false,
  });

  const handleApplicantName = (event) => {
    setApplicantName(event.target.value);
  };

  const handleApplicantPRN = (event) => {
    setApplicantPRN(event.target.value);
  };

  const handleRTOEmail = (event) => {
    const emailValue = event.target.value;
    setRTOEmail(emailValue);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(emailValue)) {
      setEmailRTOError("Please enter a valid email address.");
    } else {
      setEmailRTOError("");
    }
  };

  // const handleEmailChange = (event) => {

  //   const emailValue = event.target.value;
  //   setEmail(emailValue);

  //   // Email validation regex
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!emailRegex.test(emailValue)) {
  //     setEmailError("Please enter a valid email address.");
  //   } else {
  //     setEmailError(""); // Clear error if email is valid
  //     setCcEmailFilled(false);
  //   }
  // };


  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue); // Save the input value as it's typed
  
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailRegex.test(emailValue)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError(""); // Clear error if email is valid
      setCcEmailFilled(false); // Reset ccEmailFilled as input is being updated
    }
  };


  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setQualificationDocs({
      ...QualificationDocs,
      [id]: checked,
    });
  };

  // const addMoreInput = () => {
  //   if (email.trim()) {
  //     setInputs([...inputs, { id: Date.now(), email }]);
  //     setEmail(""); // Clear the CC Email Id input field
  //     setCcEmailFilled(true);
  //   } else {
  //     toast.error("Please enter a CC Email address.");
  //   }
  // };



const addMoreInput = () => {
  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (email.trim() && emailRegex.test(email)) {
    setInputs([...inputs, { id: Date.now(), email }]); // Add the email to the inputs array
    setEmail(""); // Clear the CC Email Id input field
    setCcEmailFilled(true); // Mark that the email has been added
  } else {
    toast.error("Please enter a valid CC Email address.");
  }
};

  


  // Function to handle changes in the input fields
  // const handleInputChange = (id, value) => {
  //   setInputs(
  //     inputs.map((input) =>
  //       input.id === id ? { ...input, email: value } : input
  //     )
  //   );
  // };


  const handleInputChange = (id, value) => {
    setInputs((prevInputs) =>
      prevInputs.map((input) =>
        input.id === id ? { ...input, email: value } : input
      )
    );
  };

  const cancel = (id) => {
    setInputs(inputs.filter((input) => input.id !== id));
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = [];
    const invalidFiles = [];

    selectedFiles.forEach((file) => {
      if (file.type === "application/pdf") {
        validFiles.push({
          file: file,
          preview: URL.createObjectURL(file),
        });
      } else {
        invalidFiles.push(file);
      }
    });

    if (invalidFiles.length !== 0) {
      setFormValues({
        files: [], // Clear the files array on error
        error: "You have to choose only PDF files.",
        fileError: true,
      });

      toast.error("You have to choose only PDF files.");
    } else {
      setFormValues({
        files: validFiles,
        error: "",
        fileError: false,
      });
    }
  };

  const removeDeleteSingleFileFun = async (fileData) => {
    // e.preventDefault();

    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("document_id", fileData?.document_id);

    try {
      setLoading(true);

      // Call the API to remove the file
      const response =
        await postRemoveFileForMailOfVerificationQualificationApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true
      ) {
        toast.success(
          response?.data?.response?.success_msg || "File removed successfully."
        );
        getIndividualEmploymentFunction(pointerID);
        getAllDataFun();
        getAllUploadedFilesFun();
        setUploadedFiles((prevUploadedFiles) =>
          prevUploadedFiles.filter((file) => file?.document_id !== fileData?.document_id)
        );

      } else {
        console.error(
          "Error removing file:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Failed to remove file."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error removing file:", error);
    }

    // setFormValues({
    //   file: null,
    //   error: "",
    //   fileError: false,
    // });

    // const input = document.querySelector('input[type="file"]');
    // if (input) {
    //   input.value = "";
    // }



  };

  const uploadSingleFile = async (e) => {
    // e.preventDefault();

    if (formValues.files.length === 0) {
      toast.error("No file selected");
      return;
    }

    // Create a new FormData object
    const formData = new FormData();
    formData.append("pointer_id", pointerID);

    formValues.files.forEach((fileObj, index) => {
      formData.append("files[]", fileObj.file);
    });

    try {
      setLoading(true);

      const response =
        await postUploadSingleFileForMailOfVerificationQualificationApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        // handleClose();
        toast.success(
          response?.data?.response?.success_msg || "File Uploaded Successfully."
        );
        // getIndividualEmploymentFunction(pointerID);
        // getAllDataFun();

        getAllUploadedFilesFun();




           // Reset the file input field
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clears the file input
      }

      // Clear the selected files from the state
      setFormValues({
        files: [],
        error: "",
        fileError: false,
      });


      } else {
        setLoading(false);
        console.error(
          "Error updating stage status data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Failed to update.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating stage status data:", error);
    }
  };


































  
  const [showTRAModal, setShowTRAModal] = useState(false);

  const handleTRAOpen = () => {

    if (!ApplicantName || !ApplicantPRN || !RTOEmail) {
      toast.error("Please fill in all required fields.");
      return;
    }

    setShowTRAModal(true);
  };

  const handleTRAClose = () => {
    setShowTRAModal(false);
  };


  const handleRequestAgainTRA = async () => {
    // e.preventDefault();

    if (!ApplicantName || !ApplicantPRN || !RTOEmail) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // if (inputs.length === 0) {
    //   toast.error("Please add at least one CC email.");
    //   return;
    // }

    // if (Object.keys(QualificationDocs).length === 0) {
    //   toast.error("Please select at least one qualification document.");
    //   return;
    // }

    // Create a new FormData object
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("applicant_name", ApplicantName);
    formData.append("applicant_prn", ApplicantPRN);
    formData.append("email", RTOEmail);



// if (inputs.length === 0) {
//   formData.append(`email_cc[]`, "");
// } else {
//   inputs.forEach((input, index) => {
//     formData.append(`email_cc[]`, input?.email);
//   });
// }

if (inputs.length === 0 && email.trim()) {
  // If no emails have been added via the "plus" button, but there's a typed email, append it
  formData.append(`email_cc[]`, email); 
} else if (inputs.length > 0) {
  // Append all emails from the inputs array
  inputs.forEach((input) => {
    if (input?.email) {
      formData.append(`email_cc[]`, input.email); // Append only the email value
    }
  });

  // Also append the current email in the field if it's not already added to inputs
  if (email.trim() && !ccEmailFilled) {
    formData.append(`email_cc[]`, email);
  }
}




// Assuming QualificationDocs is your object
if (Object.keys(QualificationDocs).length === 0) {
  // Append an empty value for document_ids[] if QualificationDocs is empty
  formData.append("document_ids[]", ""); // Or you can use `null` if preferred
} else {
  Object.keys(QualificationDocs).forEach((docId) => {
    if (QualificationDocs[docId]) {
      formData.append("document_ids[]", docId);
    }
  });
}


    // // Append the file if it exists
    // if (formValues.file) {
    //   formData.append("file", formValues.file.file);
    // }

    // // Log the formData entries to the console for verification
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    try {
      setLoading(true);

      const response =
        await postSendAllDataForMailOfVerificationQualificationApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {


        toast.success(
          response?.data?.response?.success_msg || "Mail Sended Successfully."
        );


        getAllUploadedFilesFun();
        getIndividualEmploymentFunction(pointerID);
        getAllDataFun();
        handleClose();


      } else {
        setLoading(false);
        console.error(
          "Error updating stage status data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Failed to update.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating stage status data:", error);
    }
  };

  const getAllDataFun = async (e) => {
    // e.preventDefault();

    try {
      setLoading(true);

      const response =
        await getAllDataForQualificationVerificationMailAppManager(pointerID);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const responseData = response?.data?.response?.data;

        // Prefill form fields with incoming data
        setApplicantName(responseData?.applicant_name);
        setApplicantPRN(responseData?.PRN);
        setRTOEmail(responseData?.email_id);

        // setInputs(responseData?.email_cc || []);

 
        if (responseData?.email_cc && Array.isArray(responseData.email_cc)) {
          setInputs(
            responseData.email_cc.map((email) => ({
              id: Date.now() + Math.random(), // Generate unique ID for each email
              email: email // Set the email field
            }))
          );
        }



        // If there are qualification documents, preselect them
        const docSelections = {};
        responseData?.documents.forEach((doc) => {
          if (responseData?.documents?.includes(doc?.document_id)) {
            docSelections[doc?.document_id] = true;
          }
        });

        setQualificationDocs(docSelections);

        setQualificationLinks(responseData?.documents);


      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        // toast.error(
        //   response?.data?.response?.error_msg || "Error in fetching data"
        // );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // console.log("Badshahhhhhhhhhhh");
    // getAllDataFun();

    if (pointerID && call) {
      getAllDataFun();
    }
  }, [pointerID, call]);

  const getAllUploadedFilesFun = async (e) => {
    // e.preventDefault();

    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    try {
      setLoading(true);

      const response =
        await getAllUploadedFilesForQualificationVerificationMailAppManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setUploadedFiles(response?.data?.response?.data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (pointerID && call) {
      getAllUploadedFilesFun();
    }
  }, [pointerID, call]);

  return (
    <>
      {loading && <Loader />}

      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header>
          <Modal.Title className="modal-title text-center text-green">
            <h5>Qualification Verification</h5>
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{
              background: "none",
              border: "none",
              fontSize: "1.5rem",
              position: "absolute",
              right: "1rem",
              top: "1rem",
            }}
            onClick={handleClose}
          >
            <FaTimes />
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="zoom_details">
            <label htmlFor="meeting-id" className="form-label">
              <b style={{ fontSize: "12px" }}>
                PRN <span className="text-danger">*</span>
              </b>
            </label>
            <input
              name="meeting_id"
              type="text"
              className="form-control"
              id="meeting-id"
              value={ApplicantPRN}
              onChange={handleApplicantPRN}
              required
              disabled
            />
          </div>

          <div className="zoom_details">
            <label htmlFor="meeting-id" className="form-label">
              <b style={{ fontSize: "12px" }}>
                Applicant Name <span className="text-danger">*</span>
              </b>
            </label>
            <input
              name="meeting_id"
              type="text"
              className="form-control"
              id="meeting-id"
              value={ApplicantName}
              onChange={handleApplicantName}
              required
              disabled
            />
          </div>

          <div className="zoom_details">
            <label htmlFor="meeting-id" className="form-label">
              <b style={{ fontSize: "12px" }}>
                Email Id <span className="text-danger">*</span>
              </b>
            </label>
            <input
              name="meeting_email"
              type="email"
              className="form-control"
              id="meeting-email"
              value={RTOEmail}
              onChange={handleRTOEmail}
              required
            />
            {emailRTOError && <p className="text-danger">{emailRTOError}</p>}
          </div>






































          <div className="zoom_details">
            <label htmlFor="meeting-id" className="form-label">
              <b style={{ fontSize: "12px" }}>
                CC Email Id 
             
              </b>
            </label>

            <div id="wrapper-50" className="input-group mb-2">
              <div className="col-10 p-0">
                <input
                  type="email"
                  name="email_cc[]"
                  id="email_cc"
                  value={email}
                  onChange={handleEmailChange}
                  list="email_master"
                  className="form-control"
                />
                {emailError && <p className="text-danger">{emailError}</p>}
              </div>

              <div className="col-2">
                <button
                  type="button"
                  onClick={addMoreInput}
                  disabled={ccEmailFilled}
                  className="btn btn_yellow_green add_button ml-3"
                >
                  <i className="bi bi-plus-lg"></i>
                </button>
              </div>
            </div>



            {inputs.map((input) => (
  <div key={input.id} id={`wrapper-${input.id}`} className="row mb-2">
    <div className="col-10">
      <input
        type="email"
        name="email_cc[]"
        id="email_cc"
        className="form-control"
        value={input.email} // Access the email property of the input object
        onChange={(e) => handleInputChange(input?.id, e.target.value)} // Pass input.id and new value
      />
    </div>
    <div className="col-2">
      <button
        type="button"
        onClick={() => cancel(input?.id)}
        className="btn btn-danger delete_button ml-2"
      >
        <i className="bi bi-x-lg"></i>
      </button>
    </div>
  </div>
))}



            
          </div>






















          <div className="zoom_details">
            <label htmlFor="meeting-id" className="form-label">
              <b style={{ fontSize: "12px" }}>Qualification Document:</b>
            </label>
            {qualificationLinks.map((doc) => (
              <Form.Check
                key={doc?.document_id}
                type="checkbox"
                id={doc?.document_id}
                style={{cursor:"pointer"}}
                label={
                  <a
                    className="a"
                    href={doc.document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {doc?.name}
                  </a>
                }
                checked={QualificationDocs[doc?.document_id] || false}
                onChange={handleCheckboxChange}
              />
            ))}
          </div>

          <Form.Group className="mb-0" controlId="extra_file">
            <b style={{ fontSize: "12px" }}>Files</b>
            <div className="row align-items-center">
              <div className="col-8 col-md-9 d-flex">
                <input
                  name="files"
                  type="file"
                  className="form-control"
                  required
                  onChange={handleFileChange}
                  accept=".pdf"
                  multiple
                  ref={fileInputRef}
                  style={{height:"35px"}}
                />
              </div>

              <div className="col-4 col-md-3 d-flex justify-content-end">
                <button
                  className="btn_modal"
                  onClick={uploadSingleFile}
                  disabled={
                    formValues.fileError || formValues.files.length === 0
                  }
                  style={{
                    padding: "7px 20px",
                    borderRadius: "6px",
                    border: "none",
                    cursor:
                      formValues.fileError || formValues.files.length === 0
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  Upload
                </button>
              </div>
              <div className="text-danger">Only: .pdf</div>
            </div>

            {formValues.fileError && (
              <p className="text-danger">{formValues.error}</p>
            )}

            <div>
              <>
            {uploadedFiles && uploadedFiles?.length > 0 && (

                  <div className="row mt-2">
                    {uploadedFiles.map((file, index) => (
                      <div key={file?.document_id} className="col-12 mb-2">
                        <div className="row">
                          <div className="col-8 col-md-9">
                            <span style={{ fontWeight: "bold" }}>
                              {index + 1}.{" "}
                            </span>

                            <a
                              href={`${file?.document_path}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                cursor: "pointer",
                                color: "blue",
                              }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.textDecoration =
                                  "underline")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.textDecoration = "none")
                              }
                            >
                              {file?.document_name}
                            </a>
                          </div>
                          <div className="col-4 col-md-3 d-flex justify-content-end">
                            <Button
                              className="btn btn-danger"
                              onClick={() => removeDeleteSingleFileFun(file)}
                              disabled={false}
                            >
                              <i className="bi bi-trash-fill"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            </div>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn_yellow_green"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={handleClose}
          >
            Close
          </button>

          <button
            className="btn_modal"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" , cursor:"pointer"}}
            variant="primary"
            onClick={handleTRAOpen}
          >
            Send Email
          </button>
        </Modal.Footer>
      </Modal>









      {showTRAModal && (
        <VerificationModal
          show={showTRAModal}
          onClose={handleTRAClose}
          // onYes={() => handleRequestAgainTRA}
          onYes={handleRequestAgainTRA}

          Title="Confirm !"
          Message={
            <>
              Are you sure you want to send qualification verification email to{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
              
              {ApplicantName}
              
              </span>
              ?
            </>
          }
        />
      )}



    </>
  );
};

export default EditRTODetailsModal;
