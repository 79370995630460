import React, { useState, useEffect } from "react";
import { Accordion, Col, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postSaveSingleFieldOfViewUpdateOfApplicationManager } from "../../../api";
import Loader from "../../Widgets/Loader/Loader";

const Applicant_Data = ({
  StageHeading,
  API_Profile_Data,
  Aqato_Team_Members,
  ATTC_Team_Members,

  // Programs,
  // Pathways,

  pointerID,
  getAllDataOfViewUpdateFunction,
}) => {
  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";

  const Pathways = [{ pathway: "Pathway 1" }, { pathway: "Pathway 2" }];
  const Programs = [{ program: "TSS" }, { program: "OSAP" }];

  const Occupations = [{ occupation: "Chef" }, { occupation: "Cook" }];

  const [selectedAQATOTeamMember, setSelectedAQATOTeamMember] = useState("");
  const [isAQATOSaved, setIsAQATOSaved] = useState(false);

  const [selectedATTCTeamMember, setSelectedATTCTeamMember] = useState("");
  const [isATTCSaved, setIsATTCSaved] = useState(false);

  const [selectedProgram, setSelectedProgram] = useState("");
  const [isProgramSaved, setIsProgramSaved] = useState(false);

  const [selectedOccupation, setSelectedOccupation] = useState("");
  const [isOccupationSaved, setIsOccupationSaved] = useState(false);

  const [selectedPathway, setSelectedPathway] = useState("");
  const [isPathwaySaved, setIsPathwaySaved] = useState(false);

  const [attcTicketNumber, setAttcTicketNumber] = useState("");
  const [isATTCTickedSaved, setIsATTCTickedSaved] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (API_Profile_Data?.aqato_team_member_id?.value) {
      setSelectedAQATOTeamMember(API_Profile_Data?.aqato_team_member_id?.value);
      setIsAQATOSaved(true);
    }

    if (API_Profile_Data?.attc_team_member_id?.value) {
      setSelectedATTCTeamMember(API_Profile_Data?.attc_team_member_id?.value);
      setIsATTCSaved(true);
    }

    if (API_Profile_Data?.pathway) {
      setSelectedPathway(API_Profile_Data?.pathway?.value);
      setIsPathwaySaved(true);
    }

    if (API_Profile_Data?.program) {
      setSelectedProgram(API_Profile_Data?.program?.value);
      setIsProgramSaved(true);
    }

    if (API_Profile_Data?.occupation) {
      setSelectedOccupation(API_Profile_Data?.occupation?.value);
      setIsOccupationSaved(true);
    }

    if (API_Profile_Data?.attc_ticket_no?.value) {
      setAttcTicketNumber(API_Profile_Data?.attc_ticket_no?.value);
      setIsATTCTickedSaved(false);
    }
  }, [API_Profile_Data]);

  const saveSingleFieldFunction = async (formData) => {
    try {
      setLoading(true);

      const response =
        await postSaveSingleFieldOfViewUpdateOfApplicationManager(formData);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Saved Successfully."
        );
        getAllDataOfViewUpdateFunction(pointerID);
      } else {
        setLoading(false);

        console.error(
          "Error in saving",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in saving");
      }
    } catch (error) {
      console.error("Error in saving", error);
    }
  };

  const handleChangeAQATO_TeamMember = (e) => {
    setSelectedAQATOTeamMember(e.target.value);
    setIsAQATOSaved(false);
  };

  const handleChangeAttc_TeamMember = (e) => {
    setSelectedATTCTeamMember(e.target.value);
    setIsATTCSaved(false);
  };

  const handleChangeProgram = (e) => {
    setSelectedProgram(e.target.value);
    setIsProgramSaved(false);
  };

  const handleChangeOccupation = (e) => {
    setSelectedOccupation(e.target.value);
    setIsOccupationSaved(false);
  };


  const handleChangePathway = (e) => {
    setSelectedPathway(e.target.value);
    setIsPathwaySaved(false);
  };

  const toggleTicketNoEditing = () => {
    if (isATTCTickedSaved) {
      saveAttcTicketNumber();
    } else {
      setIsATTCTickedSaved(true);
    }
  };

  const handleSaveAQATOMember = () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("param", API_Profile_Data?.aqato_team_member_id?.param);
    formData.append("value", selectedAQATOTeamMember);
    saveSingleFieldFunction(formData);

    setIsAQATOSaved(true);
    setIsATTCTickedSaved(false);
    setIsPathwaySaved(false);
    setIsProgramSaved(false);
  };

  const handleSaveATTCMember = () => {
    setIsATTCSaved(true);
    setIsATTCTickedSaved(false);
    setIsPathwaySaved(false);
    setIsProgramSaved(false);
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("param", API_Profile_Data?.attc_team_member_id?.param);
    formData.append("value", selectedATTCTeamMember);

    saveSingleFieldFunction(formData);
  };

  const handleSavePathway = () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("param", API_Profile_Data?.pathway?.param);
    formData.append("value", selectedPathway);
    saveSingleFieldFunction(formData);

    setIsPathwaySaved(true);
    setIsAQATOSaved(false);
    setIsATTCTickedSaved(false);
    setIsProgramSaved(false);
  };

  const handleSaveProgram = () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("param", API_Profile_Data?.program?.param);
    formData.append("value", selectedProgram);

    saveSingleFieldFunction(formData);

    setIsProgramSaved(true);
    setIsPathwaySaved(false);
    setIsATTCSaved(false);
    setIsATTCTickedSaved(false);
  };

  const handleSaveOccupation = () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("param", API_Profile_Data?.occupation?.param);
    formData.append("value", selectedOccupation);

    saveSingleFieldFunction(formData);

    setIsOccupationSaved(true);
    setIsProgramSaved(false);
    setIsPathwaySaved(false);
    setIsATTCSaved(false);
    setIsATTCTickedSaved(false);
  };

  const saveAttcTicketNumber = () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("param", API_Profile_Data?.attc_ticket_no?.param);
    formData.append("value", attcTicketNumber);
    saveSingleFieldFunction(formData);

    setIsATTCTickedSaved(false);
  };

  return (
    <>
      {/* {loading && (
            <div className="d-flex justify-content-center mt-3">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )} */}

      {loading && <Loader />}

      <Accordion.Item eventKey="0">
        <Accordion.Header className="accordion_header">
          {StageHeading}
        </Accordion.Header>
        <Accordion.Body>
          <Table striped>
            <tbody>
              <tr>
                <td>Applicant's Name</td>
                <td className="">{API_Profile_Data?.applicant_name}</td>
              </tr>

              <tr>
                {/* <td>Date Of Birth</td> */}
                <td>D. O. B.</td>

                <td className="">{API_Profile_Data?.d_o_b}</td>
              </tr>

              {accountType !== "head_office" && (
                <>
                  <tr>
                    <td>Email</td>
                    <td className="">{API_Profile_Data?.email}</td>
                  </tr>

                  <tr>
                    <td>Contact Number</td>
                    <td className="">{API_Profile_Data?.contact_number}</td>
                  </tr>
                </>
              )}

              {/* <tr>
                <td>Program</td>
                <td className="">{API_Profile_Data?.program}</td>
              </tr>

              <tr>
                <td>Pathway</td>
                <td className="">{API_Profile_Data?.pathway}</td>
              </tr> */}



















              {/* 
<tr>
                <td>Occupation</td>
                {((API_Profile_Data?.occupation === "Chef" || API_Profile_Data?.occupation === "Cook") && accountType !== "head_office") ? (
                    <td className="">
                      <Row>
                        <Col lg={8}>
                          <select className="form-select" onChange={handleChangeCustomOccupation}>
                            <option selected={API_Profile_Data?.occupation === "Chef"}>Chef</option>
                            <option selected={API_Profile_Data?.occupation === "Cook"}>Cook</option>
                          </select>
                        </Col>

                        <Col lg={4} className="my-auto">
                        {selectedProgram !==
                            API_Profile_Data?.program?.value &&
                            !isProgramSaved && (
                            <button
                              className="btn btn-sm btn_green_yellow"
                              title="Save"
                              onClick={handleSaveCustomOccupation}
                            >
                              <i className="bi bi-check-lg"></i>
                            </button>
                            )}
                        </Col>
                      </Row>
                    </td>
                ) : (
                  <td className="">{API_Profile_Data?.occupation}</td>
                )}
              </tr>

 */}















              {API_Profile_Data?.occupation?.value !== "" &&
                API_Profile_Data?.occupation?.value !== "undefined" && (
                  <tr>
                    <td width="30%">Occupation</td>
                    <td className="w-25">
                      <Row>
                        <Col lg={8}>
                          {accountType === "head_office" ? (
                            <input
                              type="text"
                              // value={
                              //   Occupations?.find(
                              //     (occu) => occu.occupation === selectedOccupation
                              //   )?.occupation || ""
                              // }
                              value={API_Profile_Data?.occupation?.value}
                              disabled
                              className="form-control"
                              style={{ cursor: "not-allowed" }}
                            />
                          ) : (
                            <>
                             {API_Profile_Data?.occupation?.value === "Cook" || 
   API_Profile_Data?.occupation?.value === "Chef" ? (
    <select
      value={selectedOccupation}
      onChange={handleChangeOccupation}
      className="form-select"
      style={{ cursor: "pointer" }}
    >
      {Occupations?.map((occu) => (
        <option
          key={occu?.occupation}
          value={occu?.occupation}
          style={{ cursor: "pointer" }}
        >
          {occu?.occupation}
        </option>
      ))}
    </select>
  ) : (
    <input
      type="text"
      value={API_Profile_Data?.occupation?.value}
      disabled
      className="form-control"
      style={{ cursor: "not-allowed" }}
    />
  )}

               
                            </>
                          )}
                        </Col>

                        <Col lg={4} className="my-auto">
                          {selectedOccupation !==
                            API_Profile_Data?.occupation?.value &&
                            !isOccupationSaved && (
                              <button
                                className="btn btn-sm btn_green_yellow"
                                title="Save"
                                onClick={handleSaveOccupation}
                              >
                                <i className="bi bi-check-lg"></i>
                              </button>
                            )}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )}











              {API_Profile_Data?.program?.value !== "" &&
                API_Profile_Data?.program?.value !== "undefined" && (
                  <tr>
                    <td width="30%">Program</td>
                    <td className="w-25">
                      <Row>
                        <Col lg={8}>
                          {accountType === "head_office" ? (
                            <input
                              type="text"
                              value={
                                Programs?.find(
                                  (pgm) => pgm.program === selectedProgram
                                )?.program || ""
                              }
                              disabled
                              className="form-control"
                              style={{ cursor: "not-allowed" }}
                            />
                          ) : (
                            <select
                              value={selectedProgram}
                              onChange={handleChangeProgram}
                              className="form-select"
                              style={{ cursor: "pointer" }}
                            >
                              {Programs?.map((pgm) => (
                                <option
                                  key={pgm.program}
                                  value={pgm.program}
                                  style={{ cursor: "pointer" }}
                                >
                                  {pgm.program}
                                </option>
                              ))}
                            </select>
                          )}
                        </Col>

                        <Col lg={4} className="my-auto">
                          {selectedProgram !==
                            API_Profile_Data?.program?.value &&
                            !isProgramSaved && (
                              <button
                                className="btn btn-sm btn_green_yellow"
                                title="Save"
                                onClick={handleSaveProgram}
                              >
                                <i className="bi bi-check-lg"></i>
                              </button>
                            )}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )}

              {API_Profile_Data?.pathway?.value !== "" &&
                API_Profile_Data?.pathway?.value !== "undefined" && (
                  <tr>
                    <td width="30%">Pathway</td>
                    <td className="w-25">
                      <Row>
                        <Col lg={8}>
                          {accountType === "head_office" ? (
                            <input
                              type="text"
                              value={
                                Pathways?.find(
                                  (ptw) => ptw.pathway === selectedPathway
                                )?.pathway || ""
                              }
                              disabled
                              className="form-control"
                              style={{ cursor: "not-allowed" }}
                            />
                          ) : (
                            <select
                              value={selectedPathway}
                              onChange={handleChangePathway}
                              className="form-select"
                              style={{ cursor: "pointer" }}
                            >
                              {Pathways?.map((ptw) => (
                                <option
                                  key={ptw.pathway}
                                  value={ptw.pathway}
                                  style={{ cursor: "pointer" }}
                                >
                                  {ptw.pathway}
                                </option>
                              ))}
                            </select>
                          )}
                        </Col>

                        <Col lg={4} className="my-auto">
                          {selectedPathway !==
                            API_Profile_Data?.pathway?.value &&
                            !isPathwaySaved && (
                              <button
                                className="btn btn-sm btn_green_yellow"
                                title="Save"
                                onClick={handleSavePathway}
                              >
                                <i className="bi bi-check-lg"></i>
                              </button>
                            )}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )}

              {accountType !== "head_office" &&
                API_Profile_Data?.aqato_team_member_id?.value !== "" &&
                API_Profile_Data?.aqato_team_member_id?.value !== "undefined" &&
                API_Profile_Data?.aqato_team_member_id?.value !== null && (
                  <tr>
                    <td width="30%">AQATO Team Member</td>
                    <td className="w-25">
                      <Row>
                        <Col lg={8}>
                          {accountType === "super_admin" ? (
                            <select
                              value={selectedAQATOTeamMember}
                              onChange={handleChangeAQATO_TeamMember}
                              className="form-select"
                              style={{ cursor: "pointer" }}
                            >
                              {Aqato_Team_Members?.map((member) => (
                                <option
                                  key={member.id}
                                  value={member.id}
                                  style={{ cursor: "pointer" }}
                                >
                                  {member.fullname}
                                </option>
                              ))}
                            </select>
                          ) : accountType === "aqato_employee" ? (
                            <input
                              type="text"
                              value={
                                Aqato_Team_Members?.find(
                                  (member) =>
                                    member.id === selectedAQATOTeamMember
                                )?.fullname || ""
                              }
                              disabled
                              className="form-control"
                              style={{ cursor: "not-allowed" }}
                            />
                          ) : null}
                        </Col>

                        <Col lg={4} className="my-auto">
                          {selectedAQATOTeamMember !==
                            API_Profile_Data?.aqato_team_member_id?.value &&
                            !isAQATOSaved && (
                              <button
                                className="btn btn-sm btn_green_yellow"
                                title="Save"
                                onClick={handleSaveAQATOMember}
                              >
                                <i className="bi bi-check-lg"></i>
                              </button>
                            )}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )}

              {API_Profile_Data?.attc_team_member_id?.value !== "" &&
                API_Profile_Data?.attc_team_member_id?.value !==
                  "undefined" && (
                  <tr>
                    <td width="30%">ATTC Team Member</td>
                    <td className="w-25">
                      <Row>
                        <Col lg={8}>
                          <select
                            value={selectedATTCTeamMember}
                            onChange={handleChangeAttc_TeamMember}
                            className="form-select"
                            style={{ cursor: "pointer" }}
                          >
                            {ATTC_Team_Members?.map((member) => (
                              <option
                                key={member.id}
                                value={member.id}
                                style={{ cursor: "pointer" }}
                              >
                                {member.fullname}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col lg={4} className="my-auto">
                          {selectedATTCTeamMember !==
                            API_Profile_Data?.attc_team_member_id?.value &&
                            !isATTCSaved && (
                              <button
                                className="btn btn-sm btn_green_yellow"
                                title="Save"
                                onClick={handleSaveATTCMember}
                              >
                                <i className="bi bi-check-lg"></i>
                              </button>
                            )}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )}

              {API_Profile_Data?.attc_ticket_no?.value !== "" &&
                API_Profile_Data?.attc_ticket_no?.value !== "undefined" && (
                  <tr>
                    <td>ATTC Ticket No.</td>
                    <td className="w-25">
                      <Row>
                        <Col lg={8}>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                              setAttcTicketNumber(e.target.value)
                            }
                            value={attcTicketNumber}
                            disabled={!isATTCTickedSaved}
                          />
                        </Col>
                        <Col lg={4} className="my-auto">
                          <button
                            className="btn btn-sm btn_green_yellow"
                            title={isATTCTickedSaved ? "Save" : "Edit"}
                            onClick={toggleTicketNoEditing}
                          >
                            <i
                              className={
                                isATTCTickedSaved
                                  ? "bi bi-check-lg"
                                  : "bi bi-pencil-square"
                              }
                            ></i>
                          </button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default Applicant_Data;
